.viewer-content {
  padding: 20px;
  background: #fff;
  border-radius: 8px;
  height: calc(100vh - 156px);
  overflow-y: auto;
  position: relative;
}

.viewer-loading {
  padding: 20px;
  text-align: center;
  color: #666;
}

.content-html {
  font-size: 16px;
  line-height: 1.6;
  color: #333;
}

/* Estilo del scrollbar */
.viewer-content::-webkit-scrollbar {
  width: 8px;
}

.viewer-content::-webkit-scrollbar-track {
  background: #f1f1f1;
  border-radius: 4px;
}

.viewer-content::-webkit-scrollbar-thumb {
  background: #888;
  border-radius: 4px;
}

.viewer-content::-webkit-scrollbar-thumb:hover {
  background: #555;
}

.content-html h1,
.content-html h2 {
  margin-bottom: 0.5em;
}

.content-html p {
  margin-bottom: 1em;
}

.content-html ul,
.content-html ol {
  margin-left: 1.5em;
  margin-bottom: 1em;
}

.content-html a {
  color: #007bff;
  text-decoration: none;
}

.content-html a:hover {
  text-decoration: underline;
}
