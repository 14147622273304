.members-container {
  padding: var(--spacing-500);
}

.invite-form {
  margin: var(--spacing-500) 0;
  display: flex;
  gap: var(--spacing-300);
}

.invite-form input {
  flex: 1;
  padding: var(--spacing-200);
  border: 1px solid var(--colors-gray-200);
  border-radius: var(--radius-100);
}

.members-list {
  display: flex;
  flex-direction: column;
  gap: var(--spacing-300);
}

.member-item {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: var(--spacing-400);
  background: var(--colors-white-100);
  border-radius: var(--radius-200);
  border: 1px solid var(--colors-gray-200);
  cursor: pointer;
  transition: all 0.2s ease;
}

.member-item:hover {
  border-color: var(--colors-purple-300);
}

.member-info {
  display: flex;
  align-items: center;
  gap: var(--spacing-300);
  flex: 1;
}

.member-avatar,
.avatar-placeholder {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  object-fit: cover;
}

.avatar-placeholder {
  background-color: var(--colors-gray-100);
  display: flex;
  align-items: center;
  justify-content: center;
  color: var(--colors-gray-300);
  font-weight: var(--font-weight-200);
  font-size: var(--font-size-400);
  text-transform: uppercase;
}

.member-details {
  display: flex;
  flex-direction: column;
  gap: var(--spacing-100);
}

.member-name {
  font-weight: var(--font-weight-200);
  color: var(--colors-gray-400);
  margin: 0;
}

.member-email {
  color: var(--colors-gray-300);
  font-size: var(--font-size-300);
  margin: 0;
}

.member-status {
  display: flex;
  align-items: center;
  gap: var(--spacing-200);
  margin-right: var(--spacing-400);
}

.role-badge {
  padding: var(--spacing-100) var(--spacing-200);
  border-radius: var(--radius-full);
  font-size: var(--font-size-200);
  font-weight: var(--font-weight-200);
}

.role-badge.admin {
  background-color: var(--colors-purple-000);
  color: var(--colors-purple-400);
}

.role-badge.editor {
  background-color: var(--colors-gray-100);
  color: var(--colors-gray-400);
}

.role-badge.viewer {
  background-color: var(--colors-gray-100);
  color: var(--colors-gray-300);
}

.status-badge.pending {
  background-color: var(--colors-gray-100);
  color: var(--colors-gray-300);
  padding: var(--spacing-100) var(--spacing-200);
  border-radius: var(--radius-full);
  font-size: var(--font-size-200);
}

.member-actions {
  display: flex;
  align-items: center;
  gap: var(--spacing-200);
}

.status {
  padding: var(--spacing-100) var(--spacing-200);
  border-radius: var(--radius-full);
  font-size: var(--font-size-200);
}

.status.pending {
  background-color: var(--colors-purple-000);
  color: var(--colors-purple-500);
}

.status.active {
  background-color: var(--colors-gray-100);
  color: var(--colors-gray-400);
}

.role {
  font-weight: var(--font-weight-200);
  color: var(--colors-gray-300);
}

.remove-button {
  padding: var(--spacing-200) var(--spacing-300);
  border: none;
  border-radius: var(--radius-100);
  background-color: var(--colors-red-300);
  color: var(--colors-white-100);
  cursor: pointer;
}

.remove-button:hover {
  background-color: var(--colors-red-400);
}

.error-message {
  color: var(--colors-red-400);
  padding: var(--spacing-300);
  margin: var(--spacing-300) 0;
  background-color: var(--colors-red-100);
  border-radius: var(--radius-100);
}

.success-message {
  color: var(--colors-gray-400);
  padding: var(--spacing-300);
  margin: var(--spacing-300) 0;
  background-color: var(--colors-gray-100);
  border-radius: var(--radius-100);
}

.invite-form button:disabled {
  background-color: var(--colors-gray-200);
  cursor: not-allowed;
}

.member-item .status {
  display: inline-block;
  padding: var(--spacing-100) var(--spacing-200);
  border-radius: var(--radius-full);
  font-size: var(--font-size-200);
  margin-top: var(--spacing-200);
}

.member-item .status.pending {
  background-color: var(--colors-purple-000);
  color: var(--colors-purple-500);
}

.member-item .status.active {
  background-color: var(--colors-gray-100);
  color: var(--colors-gray-400);
}

.member-item .role {
  text-transform: capitalize;
  font-weight: var(--font-weight-200);
}

.invite-form {
  display: flex;
  gap: var(--spacing-300);
  margin-bottom: var(--spacing-500);
}

.role-select {
  padding: var(--spacing-200);
  border: 1px solid var(--colors-gray-200);
  border-radius: var(--radius-100);
  min-width: var(--size-800);
}

.status-badge {
  font-size: 12px;
  padding: 2px 8px;
  border-radius: 12px;
  margin-top: 4px;
  display: inline-block;
}

.status-badge.pending {
  background-color: #FFF3E0;
  color: #E65100;
}

.member-details {
  display: flex;
  flex-direction: column;
}

.member-name {
  margin: 0;
  font-weight: 500;
}

.member-email {
  margin: 0;
  color: #666;
  font-size: 14px;
}

.alert {
  padding: 12px 20px;
  border-radius: 4px;
  margin-bottom: 20px;
  animation: slideIn 0.3s ease-out;
}

.alert-success {
  background-color: #d4edda;
  color: #155724;
  border: 1px solid #c3e6cb;
}

.alert-error {
  background-color: #f8d7da;
  color: #721c24;
  border: 1px solid #f5c6cb;
}

.loading-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(255, 255, 255, 0.8);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  z-index: 1000;
}

.spinner {
  width: 40px;
  height: 40px;
  border: 4px solid #f3f3f3;
  border-top: 4px solid #3498db;
  border-radius: 50%;
  animation: spin 1s linear infinite;
  margin-bottom: 10px;
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

@keyframes slideIn {
  from {
    transform: translateY(-20px);
    opacity: 0;
  }
  to {
    transform: translateY(0);
    opacity: 1;
  }
}