.plans-stats-container {
    padding: var(--spacing-500);
}

.stats-frame {
    display: flex;
    background-color: var(--colors-white-100);
    border: 1px solid var(--colors-gray-200);
    border-radius: var(--radius-200);
    padding: var(--spacing-400);
    gap: var(--spacing-800);
}

.stat-item {
    flex: 1;
    display: flex;
    flex-direction: column;
    gap: var(--spacing-100);
}

.stat-label {
    font-size: var(--font-size-300);
    color: var(--colors-gray-300);
}

.stat-value {
    font-size: var(--font-size-400);
    font-weight: var(--font-weight-200);
    color: var(--colors-gray-400);
    text-transform: capitalize;
}