.workspace-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: 100vh;
  width: 100%;
  background-color: var(--colors-gray-100);
}

.workspace-form {
  gap: 16px;
  display: flex;
  flex-direction: column;
  background-color: white;
  padding: var(--spacing-400);
  border-radius: var(--radius-400);
  width: 100%;
  max-width: 400px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.workspace-title {
  color: var(--colors-gray-400);
  font-size: 1.5rem;
  margin-bottom: var(--spacing-lg);
  text-align: center;
}

.workspace-description {
  color: var(--colors-gray-300);
  font-size: 0.9rem;
  text-align: center;
  margin-bottom: var(--spacing-lg);
}
