.landing-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
  width: 100%;
  gap: var(--spacing-200);
  background-color: var(--colors-gray-100);
}

.landing-title {
  font-size: 2rem;
  color: var(--colors-gray-400);
  margin-bottom: var(--spacing-lg);
}

.landing-buttons {
  display: flex;
  flex-direction: column;
  gap: var(--spacing-200);
  width: 300px;
}

.landing-button {
  padding: var(--spacing-md);
  background-color: var(--colors-purple-300);
  color: white;
  border: none;
  border-radius: var(--radius-lg);
  cursor: pointer;
  font-size: 1rem;
  transition: background-color 0.2s ease;
}

.landing-button:hover {
  background-color: var(--colors-purple-400);
} 