.editable-content {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.editor-container {
  height: 500px;
  display: flex;
  flex: 1;
  flex-direction: column;
  margin: var(--spacing-400);
  margin-bottom: 0;
  border: 1px solid var(--colors-gray-200);
  border-radius: 8px;
  background-color: white;
}

.editor-container .quill-editor {
  height: 100%;
  display: flex;
  flex-direction: column;
}

.editor-container .ql-toolbar {
  position: sticky;
  top: 0;
  z-index: 1;
  border: none;
  border-bottom: 1px solid var(--colors-gray-200);
  padding: var(--spacing-200) var(--spacing-400);
}

.editor-container .ql-container {
  flex: 1;
  overflow-y: auto;
  border: none;
  font-size: var(--font-size-400);
  color: var(--colors-gray-400);
  height: 100%;
}

.editor-container .ql-editor {
  height: 100%;
  padding: var(--spacing-400);
}

.footer {
  height: auto;
  min-height: var(--size-600);
  margin: 0 var(--spacing-400);
  padding: var(--spacing-200) var(--spacing-400);
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: var(--spacing-400);
  background-color: white;
}

.footer .status-text {
  color: var(--colors-gray-400);
  font-size: var(--font-size-300);
  line-height: 15px;
}

.footer .button-group {
  display: flex;
  gap: var(--spacing-300);
}

.footer button {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: var(--spacing-200);
  height: 31px;
  color: white;
  border: none;
  border-radius: 4px;
  font-size: var(--font-size-300);
  line-height: 15px;
  cursor: pointer;
  transition: background-color 0.2s ease;
  font-weight: var(--font-weight-200);
}

.footer button.btn-fill-primary {
  background-color: var(--colors-purple-300);
  color: white;
}

.footer button.btn-fill-primary:hover {
  background-color: var(--colors-purple-400);
}

.footer button.btn-outline-secondary {
  background-color: transparent;
  color: var(--colors-gray-400);
  border: 1px solid var(--colors-gray-300);
}

.footer button.btn-outline-secondary:hover {
  background-color: var(--colors-gray-100);
}

.footer button:disabled {
  background-color: var(--colors-gray-200);
  cursor: not-allowed;
}

.loading-state p {
  color: #666;
  margin: 0;
}

.save-status {
  display: flex;
  align-items: center;
  gap: 8px;
  font-size: 14px;
  color: #666;
}

.saving-indicator {
  display: flex;
  align-items: center;
  gap: 8px;
  color: #666;
}

.saved-indicator {
  color: #4CAF50;
  display: flex;
  align-items: center;
  gap: 4px;
}

.error-indicator {
  color: #f44336;
  display: flex;
  align-items: center;
  gap: 4px;
}

.spinner {
  width: 16px;
  height: 16px;
  border: 2px solid #f3f3f3;
  border-top: 2px solid #3498db;
  border-radius: 50%;
  animation: spin 1s linear infinite;
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

.footer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 16px;
  border-top: 1px solid #eee;
  background: #fff;
}

.save-manual-btn {
  background-color: #f8f9fa !important;
  border: 2px solid #333 !important;
  color: #333 !important;
  font-weight: bold !important;
  min-width: 150px;
  min-height: 35px;
  margin-right: 8px;
  text-align: center;
  justify-content: center;
  align-items: center;
  display: flex;
}

.save-manual-btn:hover {
  background-color: #e9ecef !important;
  color: #111 !important;
}

.save-manual-btn:disabled {
  background-color: #f8f9fa !important;
  border-color: #ccc !important;
  color: #aaa !important;
}

.connection-warning {
  background-color: #fff3cd;
  color: #856404;
  border: 1px solid #ffeeba;
  border-radius: 4px;
  padding: 10px 15px;
  margin-bottom: 15px;
  display: flex;
  align-items: center;
  font-size: 14px;
}

.connection-warning::before {
  content: "⚠️";
  margin-right: 8px;
  font-size: 16px;
}

.error-message {
  background-color: #f8d7da;
  color: #721c24;
  border: 1px solid #f5c6cb;
  border-radius: 4px;
  padding: 10px 15px;
  margin-bottom: 15px;
  font-size: 14px;
} 