:root {

    /*White*/
    --colors-white-100: rgb(255, 255, 255);

  /* Escala de grises */
  --colors-gray-100: rgb(246, 246, 247);    /* Tono más claro - sidebar bg */
  --colors-gray-200: rgb(228, 228, 228);    /* Tono medio claro */
  --colors-gray-300: rgb(95, 99, 104);      /* Tono medio */
  --colors-gray-400: rgb(52, 53, 58);       /* Tono oscuro - texto principal */
  
  /* Escala de morados */
  --colors-purple-000: rgb(231, 224, 255);  /* Tono más claro */
  --colors-purple-100: rgb(207, 194, 255);  /* Tono más claro */
  --colors-purple-200: rgb(155, 128, 255);  /* Tono medio claro */
  --colors-purple-300: rgb(112, 76, 239);   /* Tono medio */
  --colors-purple-400: rgb(83, 50, 201);    /* Tono medio oscuro */
  --colors-purple-500: rgb(56, 35, 130);    /* Tono más oscuro */

  /* Escala de rojos */
  --colors-red-100: rgb(255, 184, 184);     /* Tono más claro */
  --colors-red-200: rgb(250, 120, 120);     /* Tono medio claro */
  --colors-red-300: rgb(255, 82, 82);       /* Tono medio */
  --colors-red-400: rgb(230, 50, 50);       /* Tono medio oscuro */
  --colors-red-500: rgb(204, 45, 45);       /* Tono más oscuro */
  
  /* Dimensiones */
  --size-000: 0rem;          /* 0px */
  --size-100: 0.5rem;        /* 8px */
  --size-200: 1rem;          /* 16px */
  --size-300: 1.5rem;        /* 24px */
  --size-400: 2rem;          /* 32px */
  --size-500: 2.5rem;        /* 40px */
  --size-600: 3rem;          /* 48px */
  --size-700: 3.5rem;        /* 56px */
  --size-800: 4rem;          /* 64px */
  --size-900: 4.5rem;        /* 72px */
  --size-1000: 5rem;         /* 80px */
  
  /* Espaciado */
  --spacing-000: 0rem;               /* 0px */
  --spacing-100: 0.25rem;            /* 4px */
  --spacing-200: 0.5rem;             /* 8px */
  --spacing-300: 0.75rem;            /* 12px */
  --spacing-400: 1rem;               /* 16px */
  --spacing-500: 1.5rem;             /* 24px */
  --spacing-600: 2rem;               /* 32px */
  --spacing-700: 2.5rem;             /* 40px */
  --spacing-800: 3rem;               /* 48px */
  --spacing-900: 3.5rem;             /* 56px */
  --spacing-1000: 4rem;              /* 64px */
  
  /* Bordes */
 
  --radius-full: 624.938rem;         /* 9999px */
  --radius-000: 0rem;               /* 0px */
  --radius-100: 0.25rem;            /* 4px */
  --radius-200: 0.5rem;             /* 8px */
  --radius-300: 0.75rem;            /* 12px */
  --radius-400: 1rem;               /* 16px */
  
  /* Tamaños de fuente */
  --font-size-100: 0.625rem;         /* 10px */
  --font-size-200: 0.75rem;          /* 12px */
  --font-size-300: 0.875rem;         /* 14px */
  --font-size-400: 1rem;             /* 16px */
  --font-size-500: 1.25rem;          /* 20px */
  --font-size-600: 1.375rem;         /* 22px */
  --font-size-700: 1.625rem;         /* 26px */

  /* Pesos de fuente */
  --font-weight-100: 400;            /* Regular */
  --font-weight-200: 600;            /* Bold */
} 