.dropdown {
    background: var(--colors-white-100);
    border: 1px solid var(--colors-gray-200);
    border-radius: var(--radius-200);
    padding: var(--spacing-200);
    width: max-content;
    box-shadow: 0 var(--spacing-100) var(--spacing-300) rgba(0, 0, 0, 0.1);
    z-index: 1000;
}

.dropdown-item {
    display: flex;
    align-items: center;
    gap: var(--spacing-200);
    white-space: nowrap;
    width: 100%;
    justify-content: flex-start;
    padding: var(--spacing-200) var(--spacing-300);
    border: none;
    background: none;
    cursor: pointer;
    color: var(--colors-gray-400);
    border-radius: var(--radius-100);
    transition: background-color 0.2s ease;
    font-size: var(--font-size-300);
    font-weight: var(--font-weight-100);
}

.dropdown-item:hover {
    background: var(--colors-gray-100);
}
  