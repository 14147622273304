.company-settings-container {
  padding: 0 var(--spacing-500);
}

.company-settings-title {
  font-size: var(--font-size-500);
  margin-bottom: var(--spacing-500);
  color: var(--colors-gray-400);
}

.company-settings-error {
  color: var(--colors-red-300);
  margin-bottom: var(--spacing-400);
}

.company-settings-form {
  display: flex;
  flex-direction: column;
  gap: var(--spacing-200);
  max-width: 400px;
}

.company-settings-buttons {
  display: flex;
  gap: var(--spacing-200);
  flex-direction: row;
}

.loading-container,
.error-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: 200px;
  text-align: center;
}

.spinner {
  width: 40px;
  height: 40px;
  border: 4px solid #f3f3f3;
  border-top: 4px solid #3498db;
  border-radius: 50%;
  animation: spin 1s linear infinite;
  margin-bottom: 16px;
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

.alert {
  padding: 12px 20px;
  border-radius: 4px;
  margin-bottom: 16px;
  animation: slideIn 0.3s ease-out;
}

.alert-success {
  background-color: #d4edda;
  color: #155724;
  border: 1px solid #c3e6cb;
}

.alert-error {
  background-color: #f8d7da;
  color: #721c24;
  border: 1px solid #f5c6cb;
}

@keyframes slideIn {
  from {
    transform: translateY(-20px);
    opacity: 0;
  }
  to {
    transform: translateY(0);
    opacity: 1;
  }
}