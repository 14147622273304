.table-tools {
    position: fixed;
    bottom: var(--spacing-800);
    left: 50%;
    transform: translateX(-50%);
    z-index: 1000;
}

.table-tools-content {
    display: flex;
    align-items: center;
    height: 52px;
    padding: 8px 16px;
    background: var(--colors-white-100);
    border-radius: 8px;
    box-shadow: 0 1px 4px rgba(0, 0, 0, 0.2);
}

.selection-info {
    display: flex;
    align-items: center;
    gap: var(--spacing-200);
}

.selection-info .icon {
    color: var(--colors-gray-300);
}

.selection-text {
    display: flex;
    align-items: center;
    gap: var(--spacing-200);
    color: var(--colors-gray-300);
    font-size: var(--font-size-300);
}

.divider {
    width: 1px;
    height: 24px;
    margin: 0 var(--spacing-200);
    background-color: var(--colors-gray-200);
}

.btn-danger {
    display: flex;
    align-items: center;
    gap: var(--spacing-300);
    padding: var(--spacing-200) var(--spacing-400);
    color: var(--colors-red-300);
    border: none;
    background: transparent;
    cursor: pointer;
}

.btn-danger svg {
    color: var(--colors-red-300);
}

.close-button {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 36px;
    height: 36px;
    padding: var(--spacing-200);
    border: none;
    background: transparent;
    cursor: pointer;
    color: var(--colors-gray-300);
}

.close-button:hover {
    background-color: var(--colors-gray-100);
    border-radius: 4px;
} 