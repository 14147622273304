/* Estilos base para inputs */
.input {
    width: 100%;
    padding: var(--spacing-200) var(--spacing-300);
    border: 1px solid var(--colors-gray-200);
    border-radius: var(--radius-200);
    font-size: var(--font-size-300);
    transition: all 0.2s ease;
}

.input:focus {
    outline: none;
    border-color: var(--colors-purple-300);
    box-shadow: 0 0 0 1px var(--colors-purple-300);
}

.input:disabled {
    background-color: var(--colors-gray-100);
    cursor: not-allowed;
}

/* Variantes de tamaño */
.input-sm {
    padding: var(--spacing-100) var(--spacing-200);
    font-size: var(--font-size-200);
}

.input-lg {
    padding: var(--spacing-300) var(--spacing-400);
    font-size: var(--font-size-400);
}

/* Variantes de estado */
.input-error {
    border-color: var(--colors-red-300);
}

.input-error:focus {
    border-color: var(--colors-red-300);
    box-shadow: 0 0 0 1px var(--colors-red-300);
}

.input-success {
    border-color: var(--colors-purple-300);
}

.input-success:focus {
    border-color: var(--colors-purple-300);
    box-shadow: 0 0 0 1px var(--colors-purple-300);
}

/* Estilos para input-group */
.input-group {
    display: flex;
    flex-direction: column;
    gap: var(--spacing-200);
}

.input-group label {
    font-size: var(--font-size-400);
    color: var(--colors-gray-500);
}

/* Excepción para el label del avatar */
.input-group .avatar-form label {
    font-size: var(--font-size-300);
    width: auto;
    max-height: var(--size-400);
} 