.banner-header {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  gap: var(--spacing-200);
  background-color: #cfc2ff61;
  height: var(--size-800);
  align-items: center;
  padding: 0 var(--spacing-500);
}

.banner-header > div {
  display: flex;
  flex-direction: column;
}

.banner-counter {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 0.065rem;
  justify-content: flex-end;
}

.banner-counter span {
  font-size: var(--font-size-300);
}

.banner-available {
  font-size: var(--font-size-300);
  color: var(--colors-purple-300);
}

