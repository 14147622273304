.payment-summary-container {
    display: flex;
    gap: var(--spacing-800);
    min-height: 100vh;
}

.payment-summary-left {
    flex: 2;
    display: flex;
    flex-direction: column;
    gap: var(--spacing-500);
    padding: var(--spacing-600);
}

.payment-summary-right {
    background: var(--colors-gray-100);
    flex: 1 1;
    padding: var(--spacing-500);
    position: sticky;
    top: var(--spacing-500);
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.plan-price-container{
    align-items: center;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    gap: var(--spacing-200);
}
.plan-price{
    font-size: var(--font-size-300);
    font-weight: var(--font-weight-100);
    margin: 0;
    color: var(--colors-gray-400);
}


.users-list {
    display: flex;
    flex-direction: column;
    gap: var(--spacing-300);
    margin-bottom: var(--spacing-500);
}

.user-item {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: var(--spacing-300);
    border: 1px solid var(--colors-gray-200);
    border-radius: var(--radius-200);
}

.user-info {
    display: flex;
    align-items: center;
    gap: var(--spacing-300);
}

.user-avatar, .avatar-placeholder {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    object-fit: cover;
}

.avatar-placeholder {
    background-color: var(--colors-gray-200);
    display: flex;
    align-items: center;
    justify-content: center;
    color: var(--colors-gray-300);
    font-weight: var(--font-weight-200);
    text-transform: uppercase;
}

.user-details {
    display: flex;
    flex-direction: column;
    gap: var(--spacing-100);
    margin: 0;
}

.user-name {
    font-weight: var(--font-weight-200);
    color: var(--colors-gray-400);
    margin: 0;
}

.user-email {
    font-size: var(--font-size-300);
    color: var(--colors-gray-300);
    margin: 0;
}

.role-badge {
    padding: var(--spacing-100) var(--spacing-300);
    border-radius: var(--radius-full);
    font-size: var(--font-size-200);
    background-color: var(--colors-purple-000);
    color: var(--colors-purple-300);
}

.price-breakdown {
    display: flex;
    flex-direction: column;
    gap: var(--spacing-500);
    margin: var(--spacing-500) 0;
}


.plan-name{
    display: flex;
    align-items: flex-end;
    font-size: var(--font-size-300);
    justify-content: space-between;
    flex-direction: row;
}
.plan-name h3{
    font-size: var(--font-size-400);
    font-weight: var(--font-weight-200);
    margin: var(--spacing-100) 0;
}
.plan-name p{
    font-size: var(--font-size-300);
    font-weight: var(--font-weight-100);
    margin: 0;
}
.total-section {
         display: flex;
        flex-direction: row;
        font-size: var(--font-size-300);
        gap: var(--spacing-200);
        align-items: center;
        justify-content: space-between;
}

.total-amount {
    font-size: var(--font-size-500);
    font-weight: var(--font-weight-100);
    color: var(--colors-gray-400);
    font-size: var(--font-size-300);
}

.order-summary .btn {
    width: 100%;
    margin-top: var(--spacing-300);
}

.order-summary .btn-flat-secondary {
    margin-top: var(--spacing-200);
}
.btn-container{
    display: flex;
    justify-content: center;
}

.payment-summary-page {
    min-height: 100vh;
    width: 100%;
    background-color: var(--colors-white-100);
}

.payment-summary-content {
    max-width: 800px;
    margin: 0 auto;
}