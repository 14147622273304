.filters-modal-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-right: calc(-1 * var(--spacing-400));
}

.filters-modal-title {
    font-size: var(--font-size-500);
    color: var(--colors-gray-400);
}

.filters-modal-body {
    margin-bottom: var(--spacing-500);
    display: flex;
    flex-direction: column;
    gap: var(--spacing-400);
}

.filter-row {
    display: flex;
    gap: var(--spacing-600);
    align-items: center;
}

.filter-label {
    width: 160px;
}

.filter-label-text {
    color: var(--colors-gray-400);
    font-size: var(--font-size-400);
}

.filter-input-container {
    flex: 1;
    display: flex;
    gap: var(--spacing-400);
}

.filter-select-wrapper {
    flex: 1;
    position: relative;
}

.filter-input {
    width: 100%;
    padding: var(--spacing-200) var(--spacing-300);
    border: 1px solid var(--colors-gray-200);
    border-radius: var(--radius-200);
    font-size: var(--font-size-300);
    color: var(--colors-gray-300);
    background-color: white;
    cursor: pointer;
}

.filter-icon {
    position: absolute;
    right: var(--spacing-300);
    top: 50%;
    transform: translateY(-50%);
    color: var(--colors-gray-300);
    width: 16px;
    height: 16px;
}

/* Estilos específicos para los componentes de filtro */
.filter-select-wrapper .table-select-filter {
    width: 100%;
    margin: 0;
    padding: var(--spacing-200) var(--spacing-300);
    border: 1px solid var(--colors-gray-200);
    border-radius: var(--radius-200);
    font-size: var(--font-size-300);
    color: var(--colors-gray-300);
    background-color: white;
    cursor: pointer;
    appearance: none;
}

.filter-input-container .date-range-filter {
    display: flex;
    gap: var(--spacing-400);
    width: 100%;
    flex-direction: row;
}

.filter-input-container .date-filter-input {
    flex: 1;
    padding: var(--spacing-200) var(--spacing-300);
    border: 1px solid var(--colors-gray-200);
    border-radius: var(--radius-200);
    font-size: var(--font-size-300);
    color: var(--colors-gray-300);
    background-color: white;
}
