.signup-languages-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: var(--spacing-lg);
  background-color: var(--colors-gray-100);
  min-height: 100vh;
  width: 100%;
  justify-content: center;
}

.signup-languages-content {
  background-color: white;
  padding: var(--spacing-lg);
  border-radius: var(--radius-400);
  width: 100%;
  max-width: 900px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.signup-languages-footer {
  display: flex;
  padding: var(--spacing-500);
  justify-content: space-between;
  align-items: center;
}

.translation-info {
    color: var(--colors-gray-400);
    font-size: var(--font-size-400);
    padding: 0 var(--spacing-500);
    line-height: 1.5;
}

.signup-languages-footer-info {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.signup-languages-footer-info-text {
    color: var(--colors-gray-400);
    font-size: var(--font-size-300);
}