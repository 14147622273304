.home-content {
    display: flex;
    flex-direction: column;
    gap: var(--spacing-200);
    align-items: center;
}

.get-started-container {
    display: flex;
    flex-direction: column;
    padding: 0 var(--spacing-500);
    gap: var(--spacing-200);
    max-width: 800px;
}