.navbar {
  width: var(--size-900);
  height: 100vh;
  background-color: var(--colors-gray-100);
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: var(--spacing-400) 0;
  box-sizing: border-box;
  align-items: center;
}

.nav-top {
  display: flex;
  flex-direction: column;
  gap: var(--spacing-600);
}

.nav-buttons {
  display: flex;
  flex-direction: column;
  gap: var(--spacing-300);
}

.nav-logo {
  width: var(--size-500);
  height: var(--size-500);
  padding: 0;
  border: none;
  background: none;
  cursor: pointer;
  margin: 0 auto;
}

.nav-logo img {
  width: 100%;
  height: 100%;
}

.nav-button {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: var(--spacing-100);
  color: var(--colors-gray-400);
  background: none;
  border: none;
  cursor: pointer;
  padding: 0;
  margin: 0 auto;
  text-decoration: none;
}

.nav-button-icon {
  width: var(--size-500);
  height: var(--size-500);
  stroke-width: 2px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.nav-button svg {
  width: var(--size-300);
  height: var(--size-300);
  stroke-width: 2px;
}

.nav-button span {
  font-size: var(--font-size-200);
  font-weight: var(--font-weight-200);
  color: inherit;
  height: 12px;
  line-height: 12px;
}

.nav-button:hover .nav-button-icon {
  background: var(--colors-gray-200);
  border-radius: var(--radius-200);
  color: var(--colors-purple-300);
}


.nav-button.active .nav-button-icon {
  background: var(--colors-purple-000);
  border-radius: var(--radius-200);
  color: var(--colors-purple-300);
}

.nav-avatar {
  width: var(--size-600);
  height: var(--size-600);
  padding: 0;
  border: none;
  background: none;
  cursor: pointer;
  border-radius: 50%;
  overflow: hidden;
}

.nav-avatar img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  display: block;
}

.avatar-placeholder {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: var(--colors-gray-200);
  color: var(--colors-gray-400);
  font-size: var(--font-size-500);
}

.avatar-empty-state {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: var(--colors-gray-200);
    border-radius: 50%;
}

.avatar-empty-state img {
    width: 60%;
    height: 60%;
    opacity: 0.5;
}