@import '../Tabs/TabsFill.css';

.article-side-info {
  flex: 2;
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  min-height: min-content;
  background-color: var(--colors-gray-100);
}

.info-box {
  padding: var(--spacing-400);
}

.info-box:last-child {
  margin-bottom: 0;
}

.info-box h2 {
  font-size: 1rem;
  margin-bottom: var(--spacing-300);
  color: var(--colors-gray-400);
}

.transcript-segments {
  display: flex;
  flex-direction: column;
  gap: var(--spacing-200);
}

.transcript-segment {
  cursor: pointer;
}

.transcript-row {
  display: flex;
  gap: var(--spacing-200);
  align-items: flex-start;
}

.transcript-segment .timestamp {
  color: var(--colors-gray-300);
  font-size: var(--font-size-300);
  min-width: 40px;
}

.transcript-segment .transcript-text {
  color: var(--colors-gray-400);
  font-size: var(--font-size-300);
  margin: 0;
  flex-grow: 1;
  line-height: 1.2;
}

.info-box video {
  width: 100%;
  border-radius: var(--radius-lg);
}

.video-container {
  position: relative;
  width: 100%;
  padding-top: 56.25%; /* 16:9 Aspect Ratio (9 / 16 = 0.5625) */
}

.video-container video {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.events-list {
  display: flex;
  flex-direction: column;
  gap: var(--spacing-200); /* 8px spacing entre eventos */
}

.event-row {
  display: flex;
  gap: var(--spacing-200);
  align-items: flex-start;
  font-size: var(--font-size-300);
  line-height: 1.2;
  width: 100%;
}

.event-type {
  min-width: 40px; /* Ancho fijo según el diseño */
  color: var(--colors-gray-300); /* rgb(95, 99, 104) */
}

.event-description {
  flex-grow: 1;
  color: var(--colors-gray-400);
  overflow-wrap: break-word;
  word-break: break-word;
} 