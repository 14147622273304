.login-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: 100vh;
  width: 100%;
  background-color: var(--colors-gray-100);
}

.login-form {
  gap: 16px;
  display: flex;
  flex-direction: column;
  background-color: white;
  padding: var(--spacing-400);
  border-radius: var(--radius-400);
  width: 100%;
  max-width: 400px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.login-title {
  color: var(--colors-gray-400);
  font-size: 1.5rem;
  text-align: center;
}

.login-actions {
    display: flex;
    flex-direction: row;
    gap: var(--spacing-100);
    width: 100%;
}

.login-actions button {
    padding: 0;
} 

.login-actions button:hover {
    background-color: transparent;
}
.social-login{
  width: 100%;
}
.social-login-container {
  display: flex;
  flex-direction: column;
  gap: var(--spacing-100);
  width: 100%;
  align-items: center;
  border-top: 1px solid var(--colors-gray-200);
  margin-top: var(--spacing-400);
}

.error-message {
    background-color: #ffebee;
    color: #c62828;
    padding: 12px;
    border-radius: 4px;
    margin-bottom: 16px;
    text-align: center;
    font-size: 14px;
}

.btn-fill-primary.loading {
    position: relative;
    color: transparent;
    pointer-events: none;
}

.btn-fill-primary.loading::after {
    content: "";
    position: absolute;
    width: 20px;
    height: 20px;
    top: 50%;
    left: 50%;
    margin: -10px 0 0 -10px;
    border: 2px solid #ffffff;
    border-top-color: transparent;
    border-radius: 50%;
    animation: button-loading-spinner 0.8s linear infinite;
}

@keyframes button-loading-spinner {
    from {
        transform: rotate(0deg);
    }
    to {
        transform: rotate(360deg);
    }
}

.btn:disabled {
    opacity: 0.7;
    cursor: not-allowed;
}

.input:disabled {
    background-color: #f5f5f5;
    cursor: not-allowed;
}