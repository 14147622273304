.tabs-fill-container {
    display: flex;
    flex-direction: column;
    height: 100%;
  }
  
  .tabs-fill {
    display: flex;
    gap: var(--spacing-200);
    height: var(--size-600);
    padding: 0 var(--spacing-400);
    align-items: center;
  }
  
  .tabs-fill button {
    padding: 0 var(--spacing-300);
    border: none;
    background: none;
    color: var(--colors-gray-300);
    cursor: pointer;
    font-size: var(--font-size-300);
    font-weight: var(--font-weight-200);
    border-radius: 4px;
    height: var(--size-400);
  }
  
  .tabs-fill button:hover {
    background-color: var(--colors-gray-200);
  }
  
  .tabs-fill button.active {
    color: var(--colors-purple-300);
    background-color: var(--colors-purple-000);
  }
  
  .tab-fill-content {
    flex: 1;
    overflow: auto;
    position: relative;
  } 