.user-settings-main-content {
    display: flex;
    flex-direction: column;
    flex: 1;
}

.user-settings-content {
    display: flex;
    flex-direction: column;
    flex: 1;
}
.user-settings-container {
    display: flex;
    flex-direction: column;
    flex: 1;
    padding: 0 var(--spacing-500);
}
.user-settings-header {
    display: flex;
    flex: 1;
}

.user-settings-title {
  font-size: var(--font-size-500);
  margin-bottom: var(--spacing-500);
  color: var(--colors-gray-400);
}
.inputs-group-user-block{
    display: flex;
    flex-direction: column;
    gap: var(--spacing-200);
}
.user-settings-form {
  display: flex;
  flex-direction: column;
  gap: var(--spacing-200);
  max-width: 400px;
}

.user-settings-error {
  color: var(--colors-red-300);
  margin-bottom: var(--spacing-400);
}

.avatar-container {
    display: flex;
    gap: var(--spacing-400);
    align-items: flex-start;
    margin-bottom: var(--spacing-400);
}

.avatar-preview {
    width: 88px;
    height: 88px;
    border-radius: 50%;
    overflow: hidden;
    background-color: var(--colors-gray-100);
    display: flex;
    align-items: center;
    justify-content: center;
}

.avatar-image {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.avatar-placeholder {
    font-size: var(--font-size-700);
    color: var(--colors-gray-300);
}

.avatar-form {
    display: flex;
    flex-direction: column;
    gap: var(--spacing-200);
}

.avatar-input {
    margin-bottom: var(--spacing-200);
}
