.plans-container {
    padding: var(--spacing-500);
    display: flex;
    flex-direction: column;
}
.plans-cards {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(280px, 1fr));
    gap: var(--spacing-500);
    padding: var(--spacing-500);
    display: flex;
    justify-content: center;
}

.plan-card {
    background-color: var(--colors-white-100);
    border: 1px solid var(--colors-gray-200);
    border-radius: var(--radius-200);
    padding: var(--spacing-500);
    transition: all 0.3s ease;
}

.plan-card:hover {
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
    transform: translateY(-4px);
}

.plan-card.active {
    border: 2px solid var(--colors-purple-300);
    background-color: var(--colors-gray-100);
}

.plan-title {
    font-size: var(--font-size-600);
    font-weight: var(--font-weight-200);
    color: var(--colors-gray-400);
    margin-bottom: var(--spacing-300);
}

.plan-price {
    font-size: var(--font-size-400);
    color: var(--colors-purple-300);
    font-weight: var(--font-weight-200);
    margin-bottom: var(--spacing-500);
}

.plan-features {
    display: flex;
    flex-direction: column;
    gap: var(--spacing-300);
    margin-bottom: var(--spacing-500);
}

.feature {
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: var(--font-size-300);
    color: var(--colors-gray-300);
}

.plan-card .btn {
    width: 100%;
}
