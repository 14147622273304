/* Base button styles */
.btn {
  padding: var(--spacing-200) var(--spacing-400);
  border-radius: var(--radius-full);
  font-size: var(--font-size-300);
  font-weight: var(--font-weight-200);
  cursor: pointer;
  min-height: var(--size-300);
  min-width: var(--size-600);
  display: inline-flex;
  align-items: center;
  justify-content: center;
  transition: all 0.2s ease;
}

/* Fill variants */
.btn-fill-primary {
  background-color: var(--colors-purple-300);
  color: var(--colors-white-100);
  border: none;
}

.btn-fill-primary:hover {
  background-color: var(--colors-purple-400);
}

.btn-fill-secondary {
  background-color: var(--colors-gray-100);
  color: var(--colors-gray-400);
  border: none;
}

.btn-fill-secondary:hover {
  background-color: var(--colors-gray-200);
}

.btn-fill-danger {
  background-color: var(--colors-red-300);
  color: var(--colors-white-100);
  border: none;
}

.btn-fill-danger:hover {
  background-color: var(--colors-red-400);
}

/* Outline variants */
.btn-outline-primary {
  background-color: transparent;
  border: 1px solid var(--colors-purple-300);
  color: var(--colors-purple-300);
}

.btn-outline-primary:hover {
  border-color: var(--colors-purple-400);
  color: var(--colors-purple-400);
}

.btn-outline-secondary {
  background-color: transparent;
  border: 1px solid var(--colors-gray-300);
  color: var(--colors-gray-300);
}

.btn-outline-secondary:hover {
  border-color: var(--colors-gray-400);
  color: var(--colors-gray-400);
}

.btn-outline-danger {
  background-color: transparent;
  border: 1px solid var(--colors-red-300);
  color: var(--colors-red-300);
}

.btn-outline-danger:hover {
  border-color: var(--colors-red-400);
  color: var(--colors-red-400);
}

/* Flat variants */
.btn-flat-primary {
  background-color: transparent;
  border: none;
  color: var(--colors-purple-300);
}

.btn-flat-primary:hover {
  color: var(--colors-purple-400);
  background-color: var(--colors-gray-100);
}

.btn-flat-secondary {
  background-color: transparent;
  border: none;
  color: var(--colors-gray-300);
}

.btn-flat-secondary:hover {
  color: var(--colors-gray-400);
  background-color: var(--colors-gray-100);
}

.btn-flat-danger {
  background-color: transparent;
  border: none;
  color: var(--colors-red-300);
}

.btn-flat-danger:hover {
  color: var(--colors-red-400);
  background-color: var(--colors-gray-100);
}

/* Estados */
.btn:disabled {
  opacity: 0.5;
  cursor: not-allowed;
}