.modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.modal-content {
  background-color: white;
  display: flex;
  flex-direction: column;  
  gap: var(--spacing-400);
  padding: var(--spacing-500);
  border-radius: var(--radius-200);
  min-width: 300px;
  max-width: 500px;
}

.modal-content h2 {
  color: var(--colors-gray-400);
  font-size: var(--font-size-500);
  margin: 0;
  margin-bottom: var(--spacing-300);
}

.modal-content p {
  color: var(--colors-gray-400);
  font-size: var(--font-size-300);
  margin: 0;
  margin-bottom: var(--spacing-400);
}

.modal-actions {
  display: flex;
  justify-content: flex-end;
  gap: var(--spacing-200);
}
