body {
  margin: 0;
  padding: 0;
  height: 100%;
  width: 100%;
  font-family: 'Inter', system-ui, -apple-system, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.app-container {
  display: flex;
  width: 100%;
  height: 100%;
}

/* Contenedores base */
.container-horizontal {
  display: flex;
  width: 100%;
}

.container-vertical {
  display: flex;
  flex-direction: column;
  width: 100%;
}

/* Formularios */
.form-group {
  margin-bottom: var(--spacing-md);
}

.form-group label {
  display: block;
  margin-bottom: var(--spacing-sm);
  color: var(--colors-gray-400);
}

.form-group input {
  width: 100%;
  padding: var(--spacing-sm);
  box-sizing: border-box;
  border: 1px solid var(--colors-gray-200);
  border-radius: var(--radius-lg);
}

.form-group button {
  padding: var(--spacing-sm) var(--spacing-md);
  background-color: var(--colors-purple-300);
  color: white;
  border: none;
  cursor: pointer;
  width: 100%;
  border-radius: var(--radius-lg);
}

.form-group button:hover {
  background-color: var(--colors-purple-400);
}

/* Mensajes de estado */
.message {
  text-align: center;
  padding: var(--spacing-sm);
  margin-top: var(--spacing-sm);
  border-radius: var(--radius-lg);
  background-color: var(--colors-purple-300);
  color: white;
}