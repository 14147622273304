.sidebar-secondary {
  width: 184px;
  height: 100%;
  background-color: var(--colors-gray-100);
  border-left: 1px solid var(--colors-gray-200);
  padding: var(--spacing-500) 0;
}

.sidebar__title {
  font-size: var(--font-size-400);
  color: var(--colors-gray-400);
  padding: 0 var(--spacing-400);
  margin-bottom: var(--spacing-500);
  font-weight: var(--font-weight-200);
}

.sidebar__list {
  display: flex;
  flex-direction: column;
  gap: var(--spacing-100);
  padding: 0 var(--spacing-400);
  list-style: none;
  margin: 0;
}

.sidebar__link {
  display: block;
  padding: var(--spacing-200) var(--spacing-200);
  color: var(--colors-gray-400);
  text-decoration: none;
  border-radius: var(--radius-100);
  font-size: var(--font-size-300);
  transition: background-color 0.2s ease;
  background-color: transparent;
}

.sidebar__link:hover {
    background-color: var(--colors-gray-200);
    color: var(--colors-purple-300);
}

.sidebar__link.active {
  background-color: var(--colors-purple-000);
  color: var(--colors-purple-300);
}
