.header-tool {
    padding-top: var(--spacing-400);
    padding-left: var(--spacing-500);
    padding-right: var(--spacing-500);
    padding-bottom: var(--spacing-500);
}

.header-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid var(--colors-gray-200);
    padding-bottom: var(--spacing-500);
}

.header-content {
    display: flex;
    flex-direction: column;
    gap: 0;
}

.header-title {
    color: var(--colors-gray-400);
    font-size: var(--font-size-600);
    margin: 0;
    line-height: 170%;
}

.header-description {
    color: var(--colors-gray-400);
    font-size: var(--font-size-300);
    margin: 0;
    line-height: var(--font-size-300);
}

.header-actions {
    display: flex;
    align-items: center;
    gap: var(--spacing-200);
}

.header-counter {
    display: flex;
    align-items: center;
    gap: 2px;
    color: var(--colors-gray-300);
    font-size: var(--font-size-300);
    line-height: var(--font-size-300);
}

