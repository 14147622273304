.settings-layout {
    display: flex;
    flex-direction: row;
    flex:1;
}

.settings-content {
    flex: 1;
    overflow: auto;
}
