.install-extension {
  background-color: var(--colors-purple-000);
  border-radius: var(--radius-400);
  padding: var(--spacing-600);
  display: flex;
  gap: var(--spacing-600);
}

.install-extension__content {
  flex: 1;
  display: flex;
  flex-direction: column;
  gap: var(--spacing-200);
}

.install-extension__icon {
  color: var(--colors-purple-500);
  width: fit-content;
}

.install-extension__text-content {
  display: flex;
  flex-direction: column;
  gap: var(--spacing-200);
}

.install-extension__title {
  color: var(--colors-gray-400);
  font-size: var(--font-size-500);
  font-weight: var(--font-weight-200);
  margin: 0;
}

.install-extension__description {
  color: var(--colors-gray-300);
  font-size: var(--font-size-300);
  margin: 0;
}

.install-extension__features {
  flex: 1;
  display: flex;
  flex-direction: column;
  gap: var(--spacing-200);
}

.install-extension__feature {
  display: flex;
  align-items: center;
  gap: var(--spacing-100);
  color: var(--colors-gray-300);
  font-size: var(--font-size-300);
}

.feature-check {
  color: var(--colors-gray-300);
  font-size: var(--font-size-300);
}
