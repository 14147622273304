.workspace-selector-container {
  max-width: 800px;
  margin: 0 auto;
  padding: var(--spacing-600);
}

.workspace-selector-container h2 {
  color: var(--colors-gray-400);
  font-size: var(--font-size-600);
  margin-bottom: var(--spacing-500);
  text-align: center;
}

.workspaces-list {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
  gap: var(--spacing-400);
  margin-bottom: var(--spacing-600);
}

.workspace-item {
  background-color: var(--colors-white-100);
  border: 1px solid var(--colors-gray-200);
  border-radius: var(--radius-200);
  padding: var(--spacing-400);
  cursor: pointer;
  transition: all 0.2s ease;
  display: flex;
  flex-direction: column;
  gap: var(--spacing-200);
}

.workspace-item:hover {
  border-color: var(--colors-purple-300);
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
}

.workspace-item h3 {
  color: var(--colors-gray-400);
  font-size: var(--font-size-400);
  margin: 0;
}

.role-badge {
  background-color: var(--colors-purple-000);
  color: var(--colors-purple-400);
  padding: var(--spacing-100) var(--spacing-300);
  border-radius: var(--radius-full);
  font-size: var(--font-size-200);
  font-weight: var(--font-weight-200);
  align-self: flex-start;
}

/* Estados de carga y error */
.workspace-selector-container p {
  color: var(--colors-gray-300);
  text-align: center;
  font-size: var(--font-size-300);
}

.workspace-selector-container .btn {
  margin: 0 auto;
  display: block;
}
