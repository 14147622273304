.top-bar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: var(--size-800);
  padding: 0 var(--spacing-400);
  background-color: white;
  border-bottom: 1px solid var(--colors-gray-200);
}

.top-bar .title-container {
  display: flex;
  align-items: center;
  gap: var(--spacing-000);
}

.top-bar .back-button {
  width: var(--size-300);
  height: var(--size-300);
  border: none;
  background: none;
  padding: 0;
  color: var(--colors-gray-400);
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
}

.top-bar .title {
  color: var(--colors-gray-400);
  font-size: var(--font-size-400);
  font-weight: var(--font-weight-200);
  line-height: 1.1875;
  flex: none;
  order: 1;
  flex-grow: 0;
} 