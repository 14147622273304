.tabs-container {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.tabs {
  padding:0 var(--spacing-500);
  border-bottom: 1px solid var(--colors-gray-200);
  background-color: white;
  display: flex;
  gap: var(--spacing-400);
}

.tabs button {
  position: relative;
  padding: var(--spacing-400) 0;
  border: none;
  background: none;
  color: var(--colors-gray-300);
  font-size: var(--font-size-300);
  font-weight: var(--font-weight-200);
  cursor: pointer;
  min-height: var(--spacing-800);
}

.tabs button:hover {
  color: var(--colors-purple-300);
}

.tabs button.active {
  color: var(--colors-purple-300);
}

.tabs button.active::after {
  content: '';
  position: absolute;
  bottom: -1px;
  left: 0;
  width: 100%;
  height: 2px;
  background-color: var(--colors-purple-300);
}

.tab-content {
  flex: 1;
  overflow: hidden;
  position: relative;
} 