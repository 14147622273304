.language-banner {
    padding: var(--spacing-400);
}

.language-banner-content {
    background-color: var(--colors-purple-000);
    border-radius: var(--radius-400);
    padding: var(--spacing-600) var(--spacing-500);
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: var(--spacing-500);
}

.language-banner-text {
    flex: 1;
    display: flex;
    flex-direction: column;
    gap: var(--spacing-200);
}

.language-banner-text h2 {
    color: var(--colors-gray-400);
    font-size: var(--font-size-600);
    font-weight: var(--font-weight-200);
    margin: 0;
}

.language-banner-text p {
    color: var(--colors-gray-300);
    font-size: var(--font-size-400);
    margin: 0;
    max-width: 865px;
}

.language-banner-text button {
    margin-top: var(--spacing-400);
    width: fit-content;
}

.language-banner-icon {
    color: var(--colors-gray-400);
    flex-shrink: 0;
    display: flex;
    align-items: center;
    justify-content: center;
}

/* Responsive adjustments */
@media (max-width: 768px) {
    .language-banner-content {
        flex-direction: column;
        text-align: center;
    }

    .language-banner-text button {
        margin: var(--spacing-400) auto 0;
    }
}
