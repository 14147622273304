.signup-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: 100vh;
  width: 100%;
  background-color: var(--colors-gray-100);
}

.signup-form {
  gap: 16px;
  display: flex;
  flex-direction: column;
  background-color: white;
  padding: var(--spacing-400);
  border-radius: var(--radius-400);
  width: 100%;
  max-width: 400px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.signup-title {
  color: var(--colors-gray-400);
  font-size: 1.5rem;
  margin-bottom: var(--spacing-lg);
  text-align: center;
}

.signup-actions {
    display: flex;
    flex-direction: row;
    gap: var(--spacing-100);
    width: 100%;
}

.signup-actions button {
    padding: 0;
}

.signup-actions button:hover {
    background-color: transparent;
}

.social-login{
  width: 100%;
}
.social-login-container {
  display: flex;
  flex-direction: column;
  gap: var(--spacing-100);
  width: 100%;
  align-items: center;
  border-top: 1px solid var(--colors-gray-200);
  margin-top: var(--spacing-400);
}

.error-message {
    background-color: #ffebee;
    color: var(--colors-red-500);
    padding: var(--spacing-200);
    border-radius: var(--radius-400);
    margin-bottom: var(--spacing-400);
    text-align: center;
    font-size: var(--font-size-400);
}