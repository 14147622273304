.articles-container {
  padding: var(--spacing-500);
}

.articles-header {
  margin-bottom: var(--spacing-500);
}

.page-title {
  font-size: var(--font-size-600);
  color: var(--colors-gray-400);
  margin-bottom: var(--spacing-400);
}

.articles-stats {
  margin-bottom: var(--spacing-500);
}

.stats-card {
  background: var(--colors-gray-100);
  border-radius: 8px;
  padding: var(--spacing-400);
  box-shadow: 0 2px 4px rgba(0,0,0,0.1);
}

.stats-title {
  color: var(--colors-gray-300);
  margin-bottom: var(--spacing-200);
}

.stats-number {
  font-size: var(--font-size-400);
  font-weight: var(--font-weight-200);
  margin-bottom: var(--spacing-200);
}

.stats-number .divider {
  margin: 0 var(--spacing-200);
  color: var(--colors-gray-200);
}

.bulk-actions {
  margin-bottom: var(--spacing-400);
  display: flex;
  gap: var(--spacing-400);
}

.bulk-action-btn {
  padding: var(--spacing-200) var(--spacing-400);
  background: var(--colors-purple-300);
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

.bulk-action-btn:hover {
  background: var(--colors-purple-400);
}

.edit-title-btn {
  background: none;
  border: none;
  cursor: pointer;
  padding: var(--spacing-100);
  opacity: 0.6;
}

.edit-title-btn:hover {
  opacity: 1;
}

.edit-title-container input {
  width: 100%;
  padding: var(--spacing-100) var(--spacing-200);
  border: 1px solid var(--colors-purple-300);
  border-radius: 4px;
}

.title-container {
  display: flex;
  align-items: center;
  gap: var(--spacing-200);
}

