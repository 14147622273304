.articles-table-module {
    display: flex;
    flex-direction: column;
    flex: 1;
    min-height: 0; /* Importante para que el scroll funcione correctamente */
    background: var(--colors-white-100);
    position: relative;
}

.table-header {
    position: sticky;
    top: 0;
    z-index: 10;
    background: var(--colors-white-100);
    padding: var(--spacing-500);
}

.table-actions {
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 36px;
}

.filters-button {
    display: flex;
    align-items: center;
    position: relative;
}

.filters-count {
    position: absolute;
    top: -8px;
    right: -8px;
    background: var(--colors-purple-300);
    color: var(--colors-white-100);
    width: 20px;
    height: 20px;
    border-radius: var(--radius-full);
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: var(--font-size-200);
    font-weight: var(--font-weight-200);
}

.search-container {
    width: 188px;
    height: 33px;
}

.search-input {
    width: 100%;
    height: 100%;
    padding: 8px 10px;
    border: 1px solid var(--colors-gray-200);
    border-radius: 8px;
    font-size: var(--font-size-300);
    color: var(--colors-gray-300);
}

.search-input::placeholder {
    color: var(--colors-gray-300);
}

.table-content {
    overflow-y: auto;
    min-height: 0;
    padding: 0 var(--spacing-500);
}

.table-container {
    width: 100%;
    position: relative;
}

.articles-table {
    width: 100%;
    border-collapse: collapse;
    background: var(--colors-white-100);
    overflow: hidden;
    border-top-right-radius: var(--radius-200);
    border-top-left-radius: var(--radius-200);
}

.articles-table thead {
    position: sticky;
    top: 0;
    z-index: 10;
    background: var(--colors-white-100);
}

.articles-table th {
    background: var(--colors-gray-100);
    font-size: var(--font-size-200);
    color: var(--colors-gray-400);
    padding: var(--spacing-400);
    text-align: left;
    border-bottom: 1px solid var(--colors-gray-200);
}

.articles-table td {
    font-size: var(--font-size-300);
    color: var(--colors-gray-400);
    padding: var(--spacing-400);
    text-align: left;
    border-bottom: 1px solid var(--colors-gray-200);
}

.articles-table td a {
    color: var(--colors-purple-500);
    font-weight: var(--font-weight-100);
    text-decoration: none;
}

.articles-table td a:link {
    color: var(--colors-purple-300);  /* Enlaces no visitados */
}

.articles-table td a:visited {
    color: var(--colors-purple-500);  /* Enlaces visitados */
}

.articles-table td a:hover {
    color: var(--colors-purple-200);
}

.articles-table th > div {
    display: flex;
    align-items: center;
    gap: var(--spacing-200);
}

.table-footer {
    position: sticky;
    bottom: 0;
    background: var(--colors-white-100);
    padding: var(--spacing-500);
    z-index: 10;
}

.pagination {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: var(--spacing-300);
}

.pagination button {
    padding: var(--spacing-200) var(--spacing-400);
    border-radius: 4px;
    font-size: var(--font-size-300);
    font-weight: var(--font-weight-200);
    cursor: pointer;
    min-height: 31px;
    min-width: 48px;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    transition: all 0.2s ease;
    background-color: var(--colors-gray-100);
    color: var(--colors-gray-400);
    border: none;
}

.pagination button:hover:not(:disabled) {
    background-color: var(--colors-gray-200);
}

.pagination button:disabled {
    opacity: 0.5;
    cursor: not-allowed;
}

.pagination select {
    padding: 0.5rem;
    border: 1px solid var(--colors-gray-200);
    border-radius: 4px;
}

.action-buttons {
    display: flex;
    gap: 0.5rem;
    justify-content: center;
}

.no-articles {
    text-align: center;
    padding: 2rem;
    color: var(--colors-gray-300);
}

.table-loading {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    min-height: 200px;
    gap: 1rem;
}

.loading-spinner {
    width: 40px;
    height: 40px;
    border: 4px solid #f3f3f3;
    border-top: 4px solid var(--colors-purple-300);
    border-radius: 50%;
    animation: spin 1s linear infinite;
}

@keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
}

.table-loading span {
    color: var(--colors-gray-400);
    font-size: 0.9rem;
}
