.language-stats {
  margin: var(--spacing-500) 0;
}

.stats-card {
  background: var(--colors-gray-100);
  border-radius: var(--radius-200);
  padding: var(--spacing-500);
  text-align: center;
  max-width: 300px;
}

.stats-number {
  font-size: var(--font-size-700);
  font-weight: var(--font-weight-200);
  margin: var(--spacing-300) 0;
}

.stats-number .current {
  color: var(--colors-purple-300);
}

.stats-number .divider {
  margin: 0 var(--spacing-200);
  color: var(--colors-gray-300);
}

.stats-number .limit {
  color: var(--colors-gray-300);
}

.stats-label {
  color: var(--colors-gray-300);
  font-size: var(--font-size-300);
}

.languages-list {
  display: flex;
  flex-direction: column;
  gap: var(--spacing-400);
  padding: var(--spacing-500);
}

.default-languages-list {
  display: flex;
  flex-direction: column;
  gap: var(--spacing-400);
  padding: var(--spacing-500);
}

.language-description {
  color: var(--colors-gray-400);
  font-size: var(--font-size-300);
  line-height: 1.5;
}

.default-language-info {
  display: flex;
  flex-direction: column;
  gap: var(--spacing-100);
}

.language-item {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: var(--spacing-300);
  background: var(--colors-white-100);
  border: 1px solid var(--colors-gray-200);
  border-radius: var(--radius-100);
}

.remove-language-btn {
  background: #ff5252;
  color: white;
  border: none;
  padding: 5px 10px;
  border-radius: 4px;
  cursor: pointer;
}

.remove-language-btn:hover {
  background: #ff1744;
}

.translation-info {
  color: var(--colors-gray-400);
  font-size: var(--font-size-400);
  padding: 0 var(--spacing-500);
  line-height: 1.5;
}

.settings-info-message {
  padding: var(--spacing-300);
  background-color: var(--colors-gray-100);
  border-radius: var(--radius-100);
  color: var(--colors-gray-400);
  font-size: var(--font-size-300);
  text-align: center;
} 