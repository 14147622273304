.email-sent-to {
    color: var(--colors-gray-300);
    font-size: var(--font-size-300);
    text-align: center;
}

.email-sent-to span {
    text-align: center;
    color: var(--colors-gray-400);
    font-weight: var(--font-weight-200);
}

.verification-actions {
    display: flex;
    flex-direction: column;
    gap: var(--spacing-300);
    width: 100%;
}


.verification-code-input {
    width: 100%;
    margin: var(--spacing-400) 0;
}

.verification-code-input input {
    width: 100%;
    padding: var(--spacing-300);
    font-size: var(--font-size-400);
    text-align: center;
    letter-spacing: 4px;
    border: 1px solid var(--colors-gray-200);
    border-radius: var(--radius-200);
}

.verification-code-input input:focus {
    outline: none;
    border-color: var(--colors-purple-300);
    box-shadow: 0 0 0 2px var(--colors-purple-000);
}

.verification-actions-resend {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.verification-actions-resend-container {
    display: flex;
    flex-direction: row;
    align-items: center;

}

.verification-actions-resend-container button:hover {
    background-color: transparent;
}

.error-message {
    color: var(--colors-red-300);
    font-size: var(--font-size-200);
    margin-top: var(--spacing-200);
}